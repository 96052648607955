import React from 'react'

// Config
import { config } from '../config'

const HeaderAcademicVideoDemo = () => (
  <header>
    <div className="container-md">
      <div className="grid center text-center">
        <div>
          <h1>Engaging Class Discussion</h1>
          <p className="streamer large">
              Get student input and explore perspectives to evolve understanding.
          </p>
          <div>
            <a
              data-tracker="Schedule a Demo - Header"
              className="btn secondary"
              target="_blank"
              href={`${config.demoUrl}`}
            >
              <span>Schedule a Demo</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default HeaderAcademicVideoDemo
