import React from 'react'

import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { AcademicVideo } from '../components/AcademicTrial/AcademicVideo'
import { HowItWorks } from '../components/AcademicTrial/HowItWorks'
import HeaderAcademicVideoDemo from '../components/HeaderAcademicVideoDemo'

function AcademicTrial() {
  return (
    <Layout pageName="academic-trial">
      <Nav showNavOnMobile={false} />
      <div className="academic-trial-container academic-video-demo-container">
        <HeaderAcademicVideoDemo />
        <AcademicVideo />
        <HowItWorks />
      </div>
      <Footer />
    </Layout>
  )
}

export default AcademicTrial
