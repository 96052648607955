import {useEffect, useState} from "react";
import {useWindowSize} from "../../utils/hooks";

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = typeof window !== 'undefined' && useWindowSize().width

  useEffect(() => {
    setIsMobile(windowWidth < 900)
  }, [windowWidth])

  return isMobile
}
